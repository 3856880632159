<template>
  <label class="form-field"
    ><div :title="label" class="form-label">
      {{ label }}:
      <span v-if="required" title="" class="required-indicator">*</span>
    </div>
    <input :required="value.length" class="d-none" />
    <div class="form-input">
      <editor-content :editor="editor" />
    </div>
  </label>
</template>

<script>
import {Editor, EditorContent} from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Strike from "@tiptap/extension-strike";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";

export default {
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.value, false);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
      extensions: [
        Document,
        Paragraph,
        Text,
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
        }),
        Strike,
      ],
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
